import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Menu.css";
// Icons
import { Fade, Slide } from "react-awesome-reveal";

/*
 * TODO: Refactor Menu as a functional component
 *
 * Requirements:
 * - Create a custom hook to implement dark mode named useDarkMode
 * - Switch from setState to the useDarkMode hook
 * - Use function closures instead of this for callbacks and event handlers
 * - Menu logic and behavior should remain the same
 *
 */
class Menu extends React.Component {
  state = {
    darkMode: true,
  };

  handleOnChangeMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  render() {
    const brandLogoSM = `${process.env.PUBLIC_URL}/nissan-logo-white-sm.svg`;
    // const brandLogo = this.state.darkMode
    //   ? `${process.env.PUBLIC_URL}/logo_white.svg`
    //   : `${process.env.PUBLIC_URL}/logo.svg`;

    return (
      <div>
        <div className="top-bar-sm">
          <a href="/" className="logo">
            <Slide>
              <img src={brandLogoSM} alt="Nissan Frontier" />
            </Slide>

            <div className="logo-title">
              Nissan Frontier
              <span>
                <Fade delay={1e3} cascade damping={1e-1}>
                  2023 Crew Cab PRO-4X 4x4
                </Fade>
              </span>
            </div>
          </a>
        </div>
        <div className="menu-container">
          <a href="/" className="logo" title="Nissan Frontier">
            {/* <img src={brandLogo} alt="Nissan Frontier" /> */}
            <svg
              width="61"
              height="51"
              viewBox="0 0 61 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Vector">
                <path
                  className="cls-1"
                  d="M8.74719 17.7701C11.9141 8.59544 20.6665 2.43153 30.5134 2.43153C40.3603 2.43153 49.114 8.59544 52.2809 17.7701L52.3065 17.8464H59.2363V17.0244L56.3539 16.6929C54.5775 16.487 54.1972 15.7108 53.715 14.7263L53.5919 14.4837C49.4267 5.68624 40.3655 0.00128363 30.507 0.00128363C20.6486 0.00128363 11.5873 5.68624 7.42328 14.4837L7.30536 14.7225C6.82473 15.7071 6.44022 16.4833 4.66772 16.689L1.7853 17.0244V17.8502H8.71515L8.74719 17.7701Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M52.2989 32.7352L52.2732 32.8115C49.105 41.9861 40.359 48.1488 30.507 48.1488C20.6524 48.1488 11.9065 41.9824 8.74719 32.8064L8.7203 32.7302H1.7853V33.5546L4.66644 33.8874C6.44022 34.0933 6.82473 34.8708 7.30409 35.854L7.42068 36.0903C11.5848 44.8915 20.646 50.5791 30.5045 50.5803C40.3603 50.5803 49.4216 44.8941 53.5872 36.098L53.7049 35.8578C54.1855 34.8759 54.5648 34.0983 56.3423 33.8938L59.2262 33.561V32.7365L52.2989 32.7352Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M13.8726 21.7693H12.3026V28.8072H13.8726V21.7693Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M8.82797 28.8059V21.7693H7.25667V27.0134L2.02244 21.7693H0V28.8059H1.5713V23.5262L6.84271 28.8059H8.82797Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M43.9848 21.7693L39.5516 28.8059H41.4574L42.2456 27.5495H47.4158L48.204 28.8059H50.1085L45.6779 21.7693H43.9848ZM46.6045 26.2156H43.0556L44.832 23.3878L46.6045 26.2156Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M35.5682 27.3413C35.5234 27.3489 35.3234 27.3553 35.2439 27.3553H28.9241V28.8061H35.3427C35.3965 28.8061 35.7656 28.8061 35.8553 28.7959C37.1651 28.6803 37.7611 27.5992 37.7611 26.6451C37.7611 25.6911 37.1357 24.6354 35.9476 24.5197C35.7772 24.5029 35.6061 24.4953 35.435 24.4969H31.2388C31.0825 24.4997 30.9263 24.4878 30.7723 24.4613C30.6378 24.4253 30.5198 24.3448 30.4378 24.2333C30.3557 24.1217 30.3146 23.9857 30.3211 23.8478C30.3207 23.7097 30.3656 23.5752 30.4491 23.4648C30.5325 23.3543 30.6501 23.2738 30.7838 23.2354C30.9281 23.2042 31.0761 23.1935 31.2234 23.2036H37.2805V21.7681H31.1286C30.923 21.7646 30.7175 21.7753 30.5134 21.7999C30.0108 21.8597 29.5487 22.1028 29.217 22.4819C28.8854 22.8608 28.7078 23.3486 28.7191 23.8503C28.7191 24.7218 29.2715 25.7381 30.4852 25.9121C30.7044 25.936 30.9248 25.9458 31.1453 25.9413H35.2337C35.3106 25.9413 35.49 25.9413 35.5297 25.9502C35.6988 25.9702 35.8549 26.0497 35.9696 26.1745C36.0843 26.2991 36.1499 26.4605 36.1544 26.6292C36.1589 26.7979 36.1021 26.9625 35.9942 27.093C35.8863 27.2235 35.7346 27.3112 35.5669 27.34"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M23.7849 27.3413C23.7388 27.3489 23.5388 27.3553 23.4607 27.3553H17.1396V28.8061H23.5593C23.6119 28.8061 23.9823 28.8061 24.072 28.7959C25.3818 28.6803 25.9791 27.5992 25.9791 26.6451C25.9791 25.6911 25.3575 24.6354 24.1707 24.5211C24.0003 24.5043 23.8292 24.4966 23.658 24.4982H19.4555C19.2992 24.5011 19.143 24.4892 18.989 24.4626C18.8544 24.4266 18.7362 24.3462 18.654 24.2347C18.5717 24.1231 18.5303 23.9871 18.5366 23.849C18.5361 23.7108 18.5812 23.5763 18.665 23.4658C18.7487 23.3553 18.8665 23.2749 19.0005 23.2367C19.1448 23.2055 19.2928 23.1947 19.4401 23.2049H25.4959V21.7694H19.344C19.1385 21.7659 18.9329 21.7765 18.7288 21.8012C18.2264 21.8613 17.7644 22.1045 17.4328 22.4834C17.1012 22.8624 16.9235 23.35 16.9345 23.8516C16.9345 24.7231 17.4869 25.7394 18.7006 25.9134C18.9202 25.9373 19.1411 25.9471 19.3619 25.9426H23.4491C23.5273 25.9426 23.7054 25.9426 23.7465 25.9515C23.9155 25.9715 24.0716 26.0511 24.1863 26.1757C24.301 26.3004 24.3666 26.4618 24.3711 26.6305C24.3757 26.7992 24.3187 26.9638 24.2109 27.0943C24.103 27.2249 23.9514 27.3125 23.7836 27.3413"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M61 28.8059V21.7693H59.4289V27.0134L54.1945 21.7693H52.1719V28.8059H53.7431V23.5262L59.0146 28.8059H61Z"
                  fill="white"
                />
                <path
                  className="cls-1"
                  d="M57.409 46.2356C57.6721 46.2398 57.9288 46.3213 58.1457 46.4696C58.3622 46.6179 58.5303 46.8266 58.6279 47.0693C58.725 47.3119 58.7483 47.5778 58.6937 47.8333C58.639 48.0888 58.5091 48.3227 58.3208 48.5054C58.1325 48.688 57.8938 48.8114 57.6349 48.8598C57.3761 48.9083 57.1082 48.8798 56.8658 48.7778C56.6229 48.6759 56.4165 48.5051 56.2717 48.2869C56.1264 48.0687 56.05 47.8129 56.0516 47.5517C56.0516 47.3763 56.0866 47.2027 56.155 47.0409C56.2234 46.8793 56.3237 46.7328 56.4505 46.6102C56.5767 46.4875 56.7268 46.3912 56.8913 46.3268C57.0562 46.2625 57.2318 46.2315 57.409 46.2356ZM57.409 48.6226C57.5479 48.6227 57.6858 48.595 57.8137 48.5411C57.942 48.4872 58.0577 48.4083 58.1548 48.309C58.2513 48.2098 58.3266 48.0922 58.3765 47.9634C58.4263 47.8346 58.4491 47.6972 58.4444 47.5593C58.438 47.3565 58.3717 47.1601 58.2534 46.9946C58.1351 46.829 57.9702 46.7018 57.7792 46.6287C57.5882 46.5556 57.3798 46.54 57.1798 46.5838C56.9798 46.6275 56.7974 46.7286 56.6552 46.8746C56.5131 47.0205 56.4176 47.2048 56.3804 47.4043C56.3438 47.6038 56.3667 47.8098 56.4473 47.9963C56.5274 48.1829 56.6616 48.3417 56.8329 48.4532C57.0037 48.5646 57.2042 48.6235 57.409 48.6226ZM56.896 48.2415V46.9089C57.0599 46.8786 57.226 46.8637 57.392 46.8644C57.6333 46.8644 57.9829 46.9089 57.9829 47.2557C57.9781 47.3307 57.9484 47.402 57.8986 47.4583C57.8482 47.5147 57.7808 47.5529 57.706 47.5669V47.5885C57.8344 47.6317 57.9033 47.7397 57.9394 47.9137C57.9569 48.0291 57.9914 48.1412 58.0418 48.2465H57.7357C57.6843 48.1415 57.6498 48.0292 57.6333 47.9137C57.5819 47.7041 57.4652 47.7041 57.1883 47.7041V48.2465L56.896 48.2415ZM57.1947 47.4894C57.4217 47.4894 57.6705 47.4894 57.6705 47.2862C57.6705 47.1591 57.5819 47.0765 57.3702 47.0765C57.3114 47.0749 57.2525 47.08 57.1947 47.0918V47.4894Z"
                  fill="white"
                />
              </g>
            </svg>
            <div className="logo-title">
              Nissan Frontier
              <span>
                <Fade delay={1e3} cascade damping={1e-1}>
                  2023 Crew Cab PRO-4X 4x4
                </Fade>
              </span>
            </div>
          </a>
          <Fade cascade damping={0.1}>
            <ul className="menu-nav">
              {this.props.items && this.props.items.length === 0 ? (
                <h1 className="gallery-title">Your Nissan Frontier</h1>
              ) : null}
              {this.props.items.map((item, i) => (
                <li
                  key={item}
                  onClick={() => this.props.onSelectItem(i)}
                  className={this.props.selectedItem === i ? "selected" : null}
                >
                  <h2>{item}</h2>
                </li>
              ))}
            </ul>
          </Fade>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItem: PropTypes.number,
  onSelectItem: PropTypes.func,
};

export default Menu;
