import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "./Model.css";
// Our wonderful nissan model
//5d743cb5f67c4948bb66dcdfb0b2cbe8
//c708b0e3728b412d8b15f4e16aac44a8
const MODEL_UID = "5d743cb5f67c4948bb66dcdfb0b2cbe8";
//const MODEL_UID = "9bef56071c4349c3be1b4d8af2621000";
const useSketchfabViewer = () => {
  const viewerIframeRef = useRef(null);
  const [api, setApi] = useState();
  var iframe = document.getElementById("modelNissan");
  if (iframe) {
    api.addEventListener("viewerready", function () {
      console.log("View Ready");
      api.getSceneGraph(function (err, result) {
        if (err) {
          console.log("Error getting nodes");
          return;
        }
        api.hide(1638);
        api.hide(1491);
        api.hide(1279);
        api.hide(1383);
        api.hide(1371);
        api.hide(1245);
        api.hide(1393);
        api.hide(1359);
        api.hide(1289);
        api.hide(1515);
        api.hide(1479);
        api.hide(1405);
        api.hide(1418);
        api.hide(1503);
        api.hide(1225);
        api.hide(1203);
        api.hide(1431);
        api.hide(1455);
        api.hide(1467);
        api.show(1550);
        api.show(1347);
      });
      api.getMaterialList((err, materials) => {
        const plasticMaterial = materials.find(
          (material) => material.name === "carpaint_body"
        );
        plasticMaterial.channels.AlbedoPBR.color = [
          0.137255, 0.137255, 0.137255,
        ];
        api.setMaterial(plasticMaterial, () => {
          //console.log("Updated paint color");
        });
      });
    });
  }

  const ViewerIframe = (
    <iframe
      ref={viewerIframeRef}
      className="api-iframe-model"
      id="modelNissan"
      title="nissan-configurator-viewer"
      style={{ border: 0 }}
      allowFullScreen
    />
  );

  useEffect(() => {
    // Initialize the viewer
    let enablePreload = true;
    if (isMobile) {
      enablePreload = false;
    }
    let client = new window.Sketchfab(viewerIframeRef.current);
    client.init(MODEL_UID, {
      success: setApi,
      error: () => {
        console.log("Viewer error");
      },
      camera: 0,
      preload: enablePreload, //enablePreload
      autostart: 1,
      animation_autoplay: 0,
      annotation: 0,
      annotation_tooltip_visible: 0,
      annotations_visible: 0,
      ui_animations: 0,
      ui_controls: 0,
      ui_infos: 0,
      ui_stop: 0,
      ui_watermark_link: 0,
      ui_watermark: 0,
      ui_help: 0,
      ui_vr: 0,
      ui_ar: 0,
      ui_hint: 0,
      ui_ar_help: 0,
      ui_ar_qrcode: 0,
      ui_inspector: 0,
      ui_settings: 0,
      ui_annotations: 0,
      ui_fullscreen: 0,
      transparent: 0,
      ui_loading: 1,
      ui_color: "ff4f57",
      orbit_constraint_pan: 1,
      orbit_constraint_zoom_in: 5.55,
      orbit_constraint_zoom_out: 80,
    });
  }, []);

  return [ViewerIframe, api];
};

export const Model = ({ apiRef }) => {
  const [ViewerIframe, api] = useSketchfabViewer();
  apiRef.current = api;
  return ViewerIframe;
};
