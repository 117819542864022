import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import Slider from 'react-slick';
import './GDImageViewer.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function GDImageViewer(data) {
  const [imgIds, setImgIds] = useState([]);

  const [style, setStyle] = useState({});

  const [hover, setHover] = useState(false);

  const [newWindow, setNewWindw] = useState(false);

  const [clickable, setClickable] = useState(false);

  const [modal, setModal] = useState(false);

  const [showHeader, setShowHeader] = useState(null);

  const [classNames, setClassNames] = useState(null);

  const [ids, setIds] = useState(null);

  const [excludes, setExcludes] = useState(null);

  const [imageName, setImageName] = useState(null);

  const GOOGLE_API_KEY = data.data.gkey;
  const GOOGLE_DRIVE_URL_START =
    'https://www.googleapis.com/drive/v2/files?q=%27';
  const GOOGLE_DRIVE_URL_END = '%27+in+parents&key=';
  // const GOOGLE_DRIVE_URL_END = ("%27+in+parents+and+title+%3d+%27CCPro_BG_17A_deflect_grip_offrail%27&maxResults=1000&key=");
  const GOOGLE_DRIVE_URL2_END = '%27+in+parents+and+title+%3d+%27';
  const GOOGLE_DRIVE_URL3_END = '%27&key=';
  const GOOGLE_DRIVE_IMG_URL = 'https://lh3.googleusercontent.com/d/';
  const options = data.data.options;
  const header = data.data.header;
  const name = data.data.name;
  const interiorImage = data.data.interiorImage;
  console.log('interiorImage22', interiorImage);

  const settings = {
    dots: false,
    adaptiveHeight: true,
    infinite: false,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    loadFolderData();
    //loadData();
    loadSettings(options);
  }, []);

  function loadSettings(options) {
    if (options.imageName) {
      setImageName(options.imageName);
    }
    if (options.style) {
      setStyle(options.style);
    }
    if (options.onClick) {
      setClickable(true);
      if (options.onClick.newWindow) {
        setNewWindw(true);
      }
      if (options.onClick.modal) {
        setModal(true);
      }
    }
    if (options.hover) {
      setHover(true);
    }
    if (header) {
      setShowHeader(true);
    }

    if (options.attachClass) {
      setClassNames(options.attachClass);
    }

    if (options.attachId) {
      setIds(options.attachId);
    }
    if (options.exclude) {
      setExcludes(options.exclude);
    }
  }

  async function loadFolderData() {
    await fetch(
      GOOGLE_DRIVE_URL_START +
        '1JpQn5BQp1RdglMd2myzLdoNwXHyKkeLo' +
        GOOGLE_DRIVE_URL2_END +
        data.data.name +
        GOOGLE_DRIVE_URL3_END +
        GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        // setImgIds(jsonResp.items);
        // const filter = jsonResp.items?.find((item) => item.title === name);
        //setImgIds(filter);
        loadData(jsonResp.items[0].id);
        //setImgIds((prevState) => [...prevState, filter]);
      });
  }
  async function loadData(driveId) {
    await fetch(
      GOOGLE_DRIVE_URL_START + driveId + GOOGLE_DRIVE_URL_END + GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        setImgIds(jsonResp.items);
        //setImgIds(interiorImage);
      });
  }

  function checkFormat(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  const randomIndex = Math.floor(Math.random() * imgIds.length);
  const renderImages = (className, id, exclude, item, i) => {
    return (
      <>
        {!exclude && (
          <a
            key={i}
            data-fancybox="photos"
            href={GOOGLE_DRIVE_IMG_URL + item.id}
            data-download-src={item.downloadUrl}
          >
            <img
              style={style}
              className={
                (clickable ? ' gd-pointer ' : '') +
                ' gd-img ' +
                (hover ? ' gd-pointer gd-hover ' : '') +
                className
              }
              src={GOOGLE_DRIVE_IMG_URL + item.id}
              id={id ? id : null}
              key={i}
              alt={item.title}
              referrerpolicy="no-referrer"
            />
          </a>
        )}
      </>
    );
  };

  const renderMain = (className, id, exclude, href, target, item, i) => {
    if (!R.isEmpty(href)) {
      return (
        <a
          key={i}
          data-fancybox="photos"
          href={GOOGLE_DRIVE_IMG_URL + item.id}
          data-download-src={GOOGLE_DRIVE_IMG_URL + item.id}
        >
          {renderImages(className, id, exclude, item, i)}
        </a>
      );
    }
    return renderImages(className, id, exclude, item, i);
  };
  console.log('image name', imgIds);

  return (
    <Slider {...settings}>
      {imgIds &&
        imgIds.map((item, i) => {
          const title = R.propOr('', 'title', item);
          console.log('randomIndex', randomIndex);
          if (checkFormat(item.title)) {
            //&& item.title === name
            const className = R.propOr('', title, classNames);
            const id = R.propOr('', title, ids);
            const exclude = R.propOr('', title, excludes);
            const href =
              !modal && clickable ? GOOGLE_DRIVE_IMG_URL + item.id : '';
            const target = newWindow ? '_blank' : '';
            return renderMain(className, id, exclude, href, target, item, i);
          }
        })}
      {interiorImage &&
        interiorImage.map((item, i) => {
          const title = R.propOr('', 'title', item);
          console.log('randomIndex', randomIndex);
          if (checkFormat(item.title)) {
            //&& item.title === name
            const className = R.propOr('', title, classNames);
            const id = R.propOr('', title, ids);
            const exclude = R.propOr('', title, excludes);
            const href =
              !modal && clickable ? GOOGLE_DRIVE_IMG_URL + item.id : '';
            const target = newWindow ? '_blank' : '';
            return renderMain(className, id, exclude, href, target, item, i);
          }
        })}
    </Slider>
  );
}

export default GDImageViewer;
