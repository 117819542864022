export const colors = [
  {
    label: "Red Alert",
    src: `${process.env.PUBLIC_URL}/colors/color-1.webp`,
    value: "RA",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 11,
  },
  {
    label: "Cardinal Red TriCoat",
    src: `${process.env.PUBLIC_URL}/colors/color-2.webp`,
    value: "CR",
    price: 395,
    input: "radio",
    input_name: "paint_color",
    selector: 12,
  },
  {
    label: "Tactical Green Metallic",
    src: `${process.env.PUBLIC_URL}/colors/color-3.webp`,
    value: "TG",
    price: 395,
    input: "radio",
    input_name: "paint_color",
    selector: 13,
  },
  {
    label: "Deep Blue Pearl",
    src: `${process.env.PUBLIC_URL}/colors/color-4.webp`,
    value: "DB",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 14,
  },
  {
    label: "Baja Storm Pearl",
    src: `${process.env.PUBLIC_URL}/colors/color-5.webp`,
    value: "BSP",
    price: 395,
    input: "radio",
    input_name: "paint_color",
    selector: 15,
  },
  {
    label: "Super Black",
    src: `${process.env.PUBLIC_URL}/colors/color-6.webp`,
    value: "SB",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 16,
  },
  {
    label: "Boulder Gray Pearl",
    src: `${process.env.PUBLIC_URL}/colors/color-7.webp`,
    value: "BG",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 17,
  },
  {
    label: "Gun Metallic",
    src: `${process.env.PUBLIC_URL}/colors/color-8.webp`,
    value: "GM",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 18,
  },
  {
    label: "Glacier White",
    src: `${process.env.PUBLIC_URL}/colors/color-9.webp`,
    value: "GW",
    price: 0,
    input: "radio",
    input_name: "paint_color",
    selector: 19,
  },
];

export const styling = [
  {
    label: "No Styling",
    src: `${process.env.PUBLIC_URL}/styling/styling-1.webp`,
    value: "nstyle",
    price: 0,
    input: "radio",
    input_name: "styling_options",
    selector: 14911279,
  },
  {
    label: "Sport Bar (with Utili-Track)",
    src: `${process.env.PUBLIC_URL}/styling/styling-2.webp`,
    value: "utrack",
    price: 1170,
    input: "radio",
    input_name: "styling_options",
    selector: 1491,
  },
  {
    label: "Side-Window Deflectors",
    src: `${process.env.PUBLIC_URL}/styling/styling-3.webp`,
    value: "deflect",
    price: 246,
    input: "radio",
    input_name: "styling_options",
    selector: 1279,
  },
];
export const wheels = [
  {
    label: "17” Aluminum Alloy Wheels",
    src: `${process.env.PUBLIC_URL}/wheels/wheels-1.webp`,
    value: "17A",
    price: 0,
    input: "radio",
    input_name: "wheel_type",
    selector: 993,
  },
  {
    label: "17” Beadlock-Style Aluminum Alloy Wheels",
    src: `${process.env.PUBLIC_URL}/wheels/wheels-2.webp`,
    value: "17B",
    price: 1020,
    input: "radio",
    input_name: "wheel_type",
    selector: 1562,
  },
];
export const storage = [
  {
    label: "No Storage",
    src: `${process.env.PUBLIC_URL}/storage/storage-0.webp`,
    value: "nstorage",
    price: 0,
    input: "radio",
    input_name: "storage_type",
    selector: 1383000,
  },
  {
    label: "Affiliated: Yakima Fulltilt 4",
    src: `${process.env.PUBLIC_URL}/storage/storage-2.webp`,
    value: "ftilt4",
    price: 479,
    input: "radio",
    input_name: "storage_1",
    selector: 1383,
  },
  {
    label: "Affiliated: Yakima HitchSki",
    src: `${process.env.PUBLIC_URL}/storage/storage-3.webp`,
    value: "ski",
    price: 249,
    input: "radio",
    input_name: "storage_1",
    selector: 1245,
  },
  {
    label: "Affiliated: Yakima Holdup",
    src: `${process.env.PUBLIC_URL}/storage/storage-4.webp`,
    value: "holdup",
    price: 479,
    input: "radio",
    input_name: "storage_1",
    selector: 1393,
  },
  {
    label: "Bed Tent (Crew Cab)",
    src: `${process.env.PUBLIC_URL}/storage/storage-5.webp`,
    value: "tent",
    price: 302,
    input: "radio",
    input_name: "storage_1",
    selector: 1515,
  },
  {
    label: "Bed Assist Grip",
    src: `${process.env.PUBLIC_URL}/storage/storage-6.webp`,
    value: "grip",
    price: 262,
    input: "radio",
    input_name: "storage_1",
    selector: 1479,
  },
  {
    label: "Fixed Bed Extender",
    src: `${process.env.PUBLIC_URL}/storage/storage-7.webp`,
    value: "fixext",
    price: 266,
    input: "radio",
    input_name: "storage_1",
    selector: 1405,
  },
  {
    label: "Sliding Bed Extender",
    src: `${process.env.PUBLIC_URL}/storage/storage-8.webp`,
    value: "slidext",
    price: 332,
    input: "radio",
    input_name: "storage_1",
    selector: 1418,
  },
  {
    label: "Bedliner & Bumper Step (Crew Cab)",
    src: `${process.env.PUBLIC_URL}/storage/storage-9.webp`,
    value: "bstep",
    price: 660,
    input: "radio",
    input_name: "storage_1",
    selector: 1289,
  },
  {
    label: "Bed Area Cargo Net",
    src: `${process.env.PUBLIC_URL}/storage/storage-10.webp`,
    value: "net",
    price: 78,
    input: "radio",
    input_name: "storage_1",
    selector: 1503,
  },
];

export const runningBords = [
  {
    label: "No Board",
    src: `${process.env.PUBLIC_URL}/runningBords/runningBord-0.webp`,
    value: "nboard",
    price: 0,
    input: "radio",
    input_name: "nboard",
    selector: 12251203,
  },
  {
    label: "Off-Road Step Rails",
    src: `${process.env.PUBLIC_URL}/runningBords/runningBord-1.webp`,
    value: "offrail",
    price: 800,
    input: "radio",
    input_name: "running_bord_3",
    selector: 1225,
  },
  {
    label: "Black Tube Step Rails",
    src: `${process.env.PUBLIC_URL}/runningBords/runningBord-2.webp`,
    value: "tuberail",
    price: 440,
    input: "radio",
    input_name: "running_bord_3",
    selector: 1203,
  },
];

export const interiorColors = [
  {
    label: "Charcoal Leather",
    value: "CL",
    price: 0,
    src: `${process.env.PUBLIC_URL}/interiors/interior-1.webp`,
    input: "radio",
    input_name: "interior_color_3",
    selector: 22202,
  },
  {
    label: "Charcoal cloth with Lava Red Stitch",
    value: "LRS",
    price: 990,
    src: `${process.env.PUBLIC_URL}/interiors/interior-1.webp`,
    input: "radio",
    input_name: "interior_color_3",
    selector: 22203,
  },
];

export const towings = [
  {
    label: "Tow Hitch Receiver",
    value: "towing_1",
    src: `${process.env.PUBLIC_URL}/towings/towing-1.webp`,
    price: 240,
    input: "radio",
    input_name: "towing_1",
    selector: 1433,
  },
  {
    label: "Weight-distributing Hitch Ball Mount",
    value: "towing_2",
    src: `${process.env.PUBLIC_URL}/towings/towing-3.webp`,
    price: 370,
    input: "radio",
    input_name: "towing_2",
    selector: 1467,
  },
  {
    label: "Hitch Ball Mount",
    value: "towing_3",
    src: `${process.env.PUBLIC_URL}/towings/towing-2.webp`,
    price: 65,
    input: "radio",
    input_name: "towing_2",
    selector: 1455,
  },
];

export const interiorLayouts = [
  { label: "Five seat interior", value: "five_seat", price: 0 },
  { label: "Six seat interior", value: "six_seat", price: 6500 },
  { label: "Seven seat interior", value: "seven_seat", price: 3500 },
];

export const models = [
  {
    key: "s",
    name: "Nissan Frontier",
    colors: colors,
    styling: styling,
    wheels: wheels,
    storage: storage,
    runningBords: runningBords,
    interiorColors: interiorColors,
    towings: towings,
    gallery: [
      {
        value: "CCPro_RA_17A",
        dirId: "1cZ2f5AD9YBeZzdCm3jOPYJ3tT2WuzGQc",
        videoId: "1b6re9GoeO_NS5H_ib_GnG1NJn38XP1Ww",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_RA_17B",
        dirId: "1kbQGO6yZ-8JsYLp9WhLbbbxRMsgIze3i",
        videoId: "1UEFcOHAE-EiNb06phoSfjpTegXAiQ8ic",
        imageId: "1vC6EBPELLZWsXSDGIZKy5st6j09P3UkN",
      },
      {
        value: "CCPro_CR_17A",
        dirId: "1UX6YLpaNrYbD1Al0bhqAR7zPIp7HsRFV",
        videoId: "14hNFUQnP0WHwDJPhlFVBTHFc1lCVAYGs",
        imageId: "1mE7VYeL2zYO8KmuCk0hFh7gSEfJtk3xW",
      },
      {
        value: "CCPro_CR_17B",
        dirId: "1IaP6iJJGzqr3o3y2fICtsI9aWg3UbgVo",
        videoId: "1JycRlfgqxfW3MbUOFBRCv_nAfvO1AwZg",
        imageId: "1hCpar9H3NVGxm_d7SPxhBfzjYNE1GmKO",
      },
      {
        value: "CCPro_TG_17A",
        dirId: "1KQ77Y9MhGhCUiXPvjVbvLNVBlMZFbbPm",
        videoId: "12zJaRbM3LmRX7F0YEej36NToxvlOUGm4",
        imageId: "1uajuWx_D4iQ3J_67TwmrvFI67jMWPJrZ",
      },
      {
        value: "CCPro_TG_17B",
        dirId: "1emdaZdXzhIXsJvC9xL44vttrY2z7e__Y",
        videoId: "12RFZoM-3ad-gkR0LhYGYZEn4h4bUhSc2",
        imageId: "1uajuWx_D4iQ3J_67TwmrvFI67jMWPJrZ",
      },
      {
        value: "CCPro_DB_17A",
        dirId: "1iws5h6JH6psRHd_Y2NJW_3DxPyl-8eSN",
        videoId: "16glE6ZwB_2FR5Peu8pGWaP9AEth8fu_C",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_DB_17B",
        dirId: "1CC-BZI6wsCk481R4ksTBjEm1LIPjPJYk",
        videoId: "1gXZ8N0no6Rnmr4ewYv6qlS8GCY_l_xK2",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_BSP_17A",
        dirId: "192mL96ZP0sElSdIDEBAse3WB80KD6I8M",
        videoId: "1N8Ac83V3sae7LBhaIenB8FobLZbnk5hj",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_BSP_17B",
        dirId: "1-pahSkEKJlKgQOgqWBxqn16i8Gwv87vW",
        videoId: "1Kb3iSDfGEvZe9Ndfpu7GyXSoyb3jXDsp",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_SB_17A",
        dirId: "1fr9Rcz3YHkGhQKHUYvf6cLEndbQSS_v3",
        videoId: "10LcSZPFI0_s8CyJuLhp0faV8Xx3Ydybp",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_SB_17B",
        dirId: "1dP37q7SQ3TBvyfk8vXI4RO7aPYd0hPwu",
        videoId: "1MCIhYNt-t2kZMm2uL1zYeOQdzNV5cYt5",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_BG_17A",
        dirId: "17r_189Sv5TyTgUd6_IctWpnqxQOfkNC3",
        videoId: "1wmyuyAZAOJmtB4VJ3h0KozPPBQW_u7Km",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_BG_17B",
        dirId: "13C3C3Xnx5kD4O7fAqFz9tGrMcmHN-wFU",
        videoId: "1W6O5bwB_WFH2MKbdPPrioXxxiqizq2pI",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_GM_17A",
        dirId: "1BN3SuPCnMEr-Qlo57aip_YUkTl8r_lAO",
        videoId: "1KVchOsSvjgPWzgr8boAnaZKGrAxGMGUt",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_GM_17B",
        dirId: "1zhjfP8KOymqp5Qi6G9LqNJeqVTNRnt-r",
        videoId: "1FghRGjnIl6t0EVyCaDFy7JKgBXCQWxOl",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_GW_17A",
        dirId: "1nJvhZNyrdAJ9aryTXFC9Q4JHtBLdEDVk",
        videoId: "1C9l8l1Z1EgQnv7KGLgKi7VHFWo9Q98Vg",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
      {
        value: "CCPro_GW_17B",
        dirId: "15Qq0hpgd0lDAjHhc47oCaNEjd2fqLW5f",
        videoId: "1YfLPlOTCA6U4BQkyGqKwIYMroGa2zS5-",
        imageId: "1BNURGMi9KtW9VgynfNhLwuiYN80ILhiY",
      },
    ],
    types: [
      {
        label: "2023 Crew Cab PRO-4X 4x4",
        value: "long_range_plus",
        specs: {
          range: 402,
          top_speed: 155,
          acceleration_time: 3.7,
        },
        price: 38000,
      },
    ],
  },
  {
    key: "x",
    name: "Model X",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_x/model_x_17A.png`,
        label: '20" Silver Wheels',
        value: "17A",
        price: 0,
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_x/model_x_17B.png`,
        label: '22" Onyx Black Wheels',
        value: "17B",
        price: 5500,
      },
    ],
    types: [
      {
        label: "Long Range Plus",
        value: "long_range_plus",
        specs: {
          range: 371,
          top_speed: 155,
          acceleration_time: 4.4,
        },
        price: 79900,
      },
      {
        label: "Performance",
        value: "performance",
        specs: {
          range: 341,
          top_speed: 163,
          acceleration_time: 2.6,
        },
        price: 99990,
        benefits: [
          "Quicker acceleration: 0-60 mph in 2.6s",
          "Ludicrous Mode",
          "Enhanced Interior Styling",
        ],
      },
    ],
    interiorColors: interiorColors,
    interiorLayouts: interiorLayouts,
  },
  {
    key: "y",
    name: "Model Y",
    colors: colors,
    wheels: [
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_y/model_y_17A.png`,
        label: "19’’ Gemini Wheels",
        value: "17A",
        price: 0,
      },
      {
        src: `${process.env.PUBLIC_URL}/wheels/model_y/model_y_17B.png`,
        label: "20’’ Induction Wheels",
        value: "17B",
        price: 2000,
      },
    ],
    types: [
      {
        label: "Long Range",
        value: "long_range",
        specs: {
          range: 326,
          top_speed: 135,
          acceleration_time: 4.8,
        },
        price: 45690,
      },
      {
        label: "Performance",
        value: "performance",
        specs: {
          range: 303,
          top_speed: 155,
          acceleration_time: 3.5,
        },
        price: 55690,
        benefits: [
          "Increased top speed from 135mph to 155mph",
          "21’’ Überturbine Wheels",
          "Performance Brakes",
          "Lowered suspension",
          "Aluminum alloy pedals",
        ],
      },
    ],
    interiorColors: interiorColors.slice(0, 2),
    interiorLayouts: [interiorLayouts[0], interiorLayouts[2]],
  },
];

export const initialConfig = {
  s: {
    sketchFab: null,
    car_type: "long_range_plus",
    model: "s",
    color: "BG",
    styling: "nstyle",
    wheels: "17A",
    storage: "nstorage",
    runningBords: "nboard",
    interior_color: "CL",
    towings: "towing_1",
  },
  x: {
    car_type: "long_range_plus",
    model: "x",
    color: "white",
    wheels: "17A",
    interior_color: "all_black",
    interior_layout: "five_seat",
  },
  y: {
    car_type: "long_range",
    model: "y",
    color: "white",
    wheels: "17A",
    interior_color: "all_black",
    interior_layout: "five_seat",
  },
};
